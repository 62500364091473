<template>
  <el-form-item label="录入卡号" class="card-upload-warrper">
    <div class="left">
      <el-input size="small" type="textarea" placeholder="请输入内容" v-model="textarea" rows="10" @focus="textareaFocus"
        @input="textareaInput" resize="none" />
    </div>
    <div class="right">
      <input style="display: none" type="file" ref="excel-upload-input" accept=".xlsx, .xls, .csv"
        @change="handleClick" />
      <el-button size="small" type="primary" @click="handleUpload">导入</el-button>
      <el-upload action="" style="line-height: 15px">
        <div slot="tip" class="el-upload__tip">
          <p>提示：</p>
          <p>1、导入的文件仅支持xls、xlsx、csv格式的Excel文件。</p>
          <p>
            2、请保证上传的Excel文件中所有卡号均在第一列，且每个单元格中只有1个卡号。
          </p>
          <p>3、每次最多允许导入1万条数据。</p>
        </div>
      </el-upload>
      <div style="margin-top: 75px">
        <el-button type="primary" size="small" @click="handleExportCardConfirm"
          :disabled="!shopId || !textarea">确定</el-button>
        <el-button type="default" size="small" @click="handleReset">重置</el-button>
      </div>
    </div>
  </el-form-item>
</template>

<script>
import XLSX from "xlsx";
import * as API_saleCard from "@/api/saleCard";

export default {
  name: "card-upload",
  props: {
    shopId: {
      type: String | Number,
      default: "",
    },
    cardData: {
      default: () => [],
    },
    appType: {
      type: String | Number,
      default: "",
    },
  },
  data () {
    return {
      originalData: [],
      textarea: "",
    };
  },
  mounted () { },
  methods: {
    // 导入文件方法
    textareaFocus () {
      if (!this.shopId) {
        this.$message.error("请先选择所属商城");
      }
    },
    textareaInput () {
      if (!this.shopId) {
        this.$message.error("请先选择所属商城");
      }
    },
    handleUpload () {
      if (!this.appType) return this.$message.error("请先选择应用类型");
      if (this.shopId) {
        this.$refs["excel-upload-input"].click();
      } else {
        this.$message.error("请先选择所属商城");
      }
    },
    clear () {
      this.handleReset();
    },
    handleClick (e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload (rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel
      this.readerData(rawFile);
    },
    readerData (rawFile) {
      const filename = rawFile.name;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const fixedData = this.fixData(data);
          const workbook = XLSX.read(btoa(fixedData), { type: "base64" });
          const wsname = workbook.SheetNames[0]; //取第一张表
          // const ws = XLSX.utils.sheet_to_formulae(workbook.Sheets[wsname]); //生成json表格内容

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 1,
          });
          for (let i = ws.length - 1; i >= 0; i--) {
            if (!(ws[i].length > 0 && ws[i][0] != "")) {
              ws.splice(i, 1);
            } else {
              break;
            }
          }
          if (ws.length > 10000) {
            this.$message.error("每次最多允许导入1万条数据！");
            return reject();
          }
          let list = ws.map((item, index) => {
            if (item.length === 0)
              return {
                index,
                value: "",
              };
            const [key, value] = Object.entries(item)[0];
            return {
              index,
              value: (value + "").trim(),
            };
          });
          // .filter((item) => {
          //   return item.value != "";
          // });

          if (list.length == 1 && list[0].value == "") return;

          if (list.some((item) => item.value == "")) {
            this.$message.error(
              "表格数据中间不允许存在空行，请编辑文件后重新上传"
            );
            return;
          }

          if (list[0]) {
            let reg = /^(?:\w{0,5})?\d+$/;
            if (!reg.test(list[0].value)) {
              this.$message.error(
                "导入的表格格式不正确，请重新选择文件"
              );
              return
            }
          }


          if (!this.checkErrList(list)) return;

          // this.originalData = Array.from(
          //   new Set(list.map((item) => item.value))
          // );
          this.originalData = list.map((item) => item.value);
          // console.log(ws)
          //   if (!ws.length) return;
          //   _this.originalData = ws.map((item) => {
          //     if (!/^A/.test(item)) {
          //       this.$message.error("导入的表格格式不正确，请重新选择文件");
          //       return;
          //     }
          //     return item.split("'")[1];
          //   });
          this.textarea = this.originalData
            .map((item) => this.stringLength(item))
            .join("\n");
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    fixData (data) {
      let o = "";
      let l = 0;
      const w = 10240;
      for (; l < data.byteLength / w; ++l) {
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      }
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    // 确定按钮触发事件
    handleExportCardConfirm () {
      this.originalData = this.textarea.split("\n").filter((item) => {
        if (item) return item;
      });
      if (!this.shopId) {
        return this.$message.error("请先选择所属商城");
      }
      if (!this.originalData.length) {
        this.$message.error("卡号信息为空，请选择导入或输入再继续操作!");
        return false;
      }
      let params = {
        app_type_shop_type_flag: this.appType,
        shop_id: this.shopId,
        card_code_list: this.originalData,
      };
      this.$emit("submit", params);
      // API_saleCard.exportCard(params)
      //   .then((res) => {
      //     res.forEach((item) => {
      //       item["discount_ratio"] = 100.0;
      //       item["discount_value"] = 0.0;
      //       item["money"] = item.card_num * item.card_value;
      //       item["originalCardNum"] = item.card_num;
      //     });
      //     this.formInfo.cardList = res;
      //     this.totalPriceAndDiscountPrice();
      //     this.saveDisabled = false;
      //     this.originalData = [];
      //   })
      //   .catch(() => {
      //     this.originalData = [];
      //     this.formInfo.cardList = [
      //       {
      //         card_name: "",
      //         card_value: "",
      //         card_range_list: [
      //           {
      //             card_code_start: "",
      //             card_code_end: "",
      //           },
      //         ],
      //         card_num: 0,
      //         discount_ratio: "",
      //         discount_value: "",
      //         money: "",
      //       },
      //     ];
      //     this.formInfo.total_price = 0;
      //     this.formInfo.after_discount_price = 0;
      //   });
    },
    // 判断字符串占的长度
    stringLength (str) {
      let strLen = 0;
      let newStr = "";
      for (let i = 0; i < str.length; i++) {
        if (strLen > 19) {
          newStr += "...";
          break;
        } else {
          if (str.charCodeAt(i) > 255) {
            //如果是汉字，则字符串长度加2
            strLen += 2;
          } else {
            strLen++;
          }
          newStr += str[i];
        }
      }
      return newStr;
    },
    handleReset () {
      this.originalData = [];
      this.textarea = "";
      this.$emit("clear");
    },
    getErr (list) {
      // let _map = new Map();
      // list.forEach((item) => {
      //   const { index, value } = item;
      //   let head;
      //   try {
      //     head = value.match(/^[a-zA-Z]+/)[0];
      //   } catch (error) {
      //     head = null;
      //   }
      //   if (!_map.has(head)) {
      //     _map.set(head, [{ index, value }]);
      //   } else {
      //     _map.set(head, [..._map.get(head), { index, value }]);
      //   }
      // });
      // if (_map.size == 1) return true;
      // let _max = Math.max(...Array.from(_map).map((m) => m[1].length));
      // let _errorList = [];
      // _map.forEach((m) => {
      //   if (m.length != _max) {
      //     _errorList = [..._errorList, ...m];
      //   }
      // });
      let _errorList = [];
      let reg = /^(?:\w{0,5})?\d+$/;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (!reg.test(item.value)) {
          _errorList.push(item);
        }
      }
      if (_errorList.length === 0) return true;
      let _errorStr = _errorList
        .map((item) => {
          return `第${item.index + 1}行(${item.value})`;
        })
        .join(",");
      return _errorStr || "";
    },
    checkErrList (list) {
      let _errorStr = this.getErr(list);
      let _rptStr = this.getRepeatErr(list);

      if (_errorStr === true && _rptStr === true) return true;

      let err0 = `
          <p style="font-size:12px;font-weight: bold;">规则不正确卡号在Excel表格中的位置：</p>
          <p style="font-size:12px;">${_errorStr}</p>`;
      let err1 = `
          <p style="font-size:12px;font-weight: bold;">存在重复卡号在Excel表格中的位置：</p>
          <p style="font-size:12px;">${_rptStr}</p>`;

      this.$alert(
        `<p style="font-weight: bold;">导入的卡号数据中存在卡号规则不正确，请核对后重新上传。</p>
        <div style="height: auto; max-height: 14em; overflow: auto; color: #f20;">
          ${_errorStr !== true ? err0 : ""}
          ${_rptStr !== true ? err1 : ""}
        </div>
      `,
        {
          title: "提示",
          dangerouslyUseHTMLString: true,
        }
      );
      return false;
    },
    getRepeatErr (list) {
      let _map = new Map();
      list.forEach((item) => {
        const { index, value } = item;
        if (_map.has(value)) {
          _map.set(value, [..._map.get(value), { ...item }]);
        } else {
          _map.set(value, [{ ...item }]);
        }
      });
      if (_map.size == list.length) return true;
      let _errorList = Array.from(_map)
        .map((item) => item[1])
        .filter((item) => item.length > 1)
        .flat();

      let _errorStr = _errorList
        .map((item) => {
          return `第${item.index + 1}行(${item.value})`;
        })
        .join(",");
      return _errorStr || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.card-upload-warrper {
  margin-top: 10px;

  .el-form-item__content {
    .left {
      width: 200px;
      height: 260px;

      /deep/ .el-textarea,
      /deep/ textarea {
        height: 100%;
      }
    }

    .right {
      position: absolute;
      top: 0;
      left: 240px;
    }
  }

  .el-upload__tip {
    p {
      margin-bottom: 8px;
    }
  }
}
</style>
