// 销售订单-退单，输入框的历史数据匹配建议

import * as API_saleCard from "@/api/saleCard";
import * as API_Order from "@/api/order";
import { createCardTypes } from "@/views/tools/sale-card-open/utils/type";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import Storage from "@/utils/storage";

const mixins = {
  components: {
    EnTableLayout,
    XDialog,
  },
  data() {
    return {
      clientList: [],
      agentList: [],
      linkNameList: [],
      phoneList: [],
      addressList: [],
      salesNameList: [],
      makeOrderList: [],
      shopList: [],
      cardTypes: createCardTypes(),
      errTitle: {
        1: "存在卡号规则不正确的卡号数据",
        2: "存在非当前商城的卡号数据",
        3: "存在“未激活/已消费完”的卡号数据|存在“未激活/已兑换”的卡号数据|存在“未激活/配送结束”的卡号数据",
        4: "存在被其他销售关卡单或销售退单占用的卡号数据",
        6: "存在重复卡号数据",
        7: "存在不属于当前所关联销售单的卡号数据",
        8: "存在企业被禁用",
        9: "存在卡号不属于当前所关联的销售关卡单的卡号数据",
        10: "存在被占用的卡号",
      },
      errRule: {
        1: "规则不正确的卡号,",
        2: "非当前商城的卡号,",
        3: "未激活/已消费完的卡号,|未激活/已兑换的卡号,|未激活/配送结束的卡号,",
        4: "被占用的卡号,",
        6: "重复的卡号,",
        7: "不属于当前关联销售单,",
        8: "企业被禁用,",
        9: "卡号不属于当前所关联的销售关卡单,",
        10: "被占用的卡号,",
      },
    };
  },
  methods: {
    getShopName(shop_id, list = this.shopList) {
      if (!list) return "";
      if (!shop_id) return "";
      if (list.length === 0) return "";
      try {
        let str = list.find((s) => s.shop_id === shop_id)["shop_name"];
        return str;
      } catch (error) {
        return "";
      }
    },
    getClientList() {
      API_saleCard.getCustomer().then((res) => {
        this.clientList = [].map.call(res, (item) => {
          item.value = item.name;
          return item;
        });
      });
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    // 远程搜索客户名
    queryClientSearchAsync(queryString, cb) {
      let restaurants = this.clientList;
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 100 * Math.random());
    },
    async checkPermission() {
      // const isCardSale = await API_order.getShopExtAuth().then((resStatus) => {
      //   if (resStatus) return resStatus.open === "OPEN";
      // });
      // if (isCardSale) return;
      // this.$message.error("平台已关闭销售开卡功能，如有其他问题，请联系平台");
      // this.route.go(-1);

      return;
      this.$message.error("当前企业已被禁用");
      let user = Storage.getItem("seller_user");
      let fromEnterSellerUser = JSON.parse(
        sessionStorage.getItem("from_enter_seller_user")
      );
      let member_type = (
        this.MixinIsFormEnterprise ? fromEnterSellerUser || user : user
      ).member_type;
      this.$store.dispatch("logoutAction").then(() => {
        switch (member_type) {
          case 2:
            window.location.href = "/login";
            break;
          case 3:
            window.location.href = "/login?type=enterprise";
            break;
          case 4:
            window.location.href = "/login?type=supplier";
            break;
          case 5:
            window.location.href = "/login?type=combo-card-shop";
            break;
          default:
            window.location.href = "/login";
            break;
        }
      });
    },
    handleSelectSell(item) {
      if (!item) return;
      const { id } = item;
      this.formInfo.salesman_id = id;
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length == 0) ||
        (typeof value == "string" && value.trim().length == 0)
      );
    },
    groupArrayByKey(arr = [], key) {
      return arr.reduce(
        (t, v) => (!t[v[key]] && (t[v[key]] = []), t[v[key]].push(v), t),
        {}
      );
    },
    getRule(rule, message) {
      if (this.isEmpty(rule)) return "";
      let list = [];
      for (const [key, value] of Object.entries(rule)) {
        list.push(`第${key}行(${value})`);
      }
      return `
      <p style="font-size:12px;font-weight: bold;">${message}所在文本位置：</p>
      <p style="font-size:12px;">${list.join(",")}</p>`;
    },
    checkCardExportRules2 (type, err, app_type) {
      console.log(type,app_type);
      let ruleStr = this.errRule[type];
      let titleStr = this.errTitle[type];
      if (type == 3) {
        switch (app_type) {
          case 3:
            ruleStr = ruleStr.split("|")[0];
            titleStr = titleStr.split("|")[0];
            break;
          case 5:
            ruleStr = ruleStr.split("|")[2];
            titleStr = titleStr.split("|")[2];
            break;
          default:
            ruleStr = ruleStr.split("|")[1];
            titleStr = titleStr.split("|")[1];
            break;
        }
      }
      let rule_err = this.getRule(err, ruleStr);
      this.$alert(
        `<p style="font-weight: bold;">${titleStr}，请重新填写或导入。</p>
        <div style="height: auto; max-height: 14em; overflow: auto; color: #f20;">
          ${rule_err}
        </div>
      `,
        {
          title: "提示",
          dangerouslyUseHTMLString: true,
        }
      );
    },
    checkCardRange(list, type = 1) {
      let title = "",
        msg = "";
      if (type == 1) {
        title = `当前卡号区间中存在非"未激活"状态的卡号,是否需要由系统自动将卡号区间拆分成多个卡号区间?`;
        msg = `非未激活状态的卡号`;
      }
      if (type == 2) {
        title = `当前卡号区间中存在已绑定/已禁用状态的卡号，是否需要由系统自动将卡号区间拆分成多个卡号区间?`;
        msg = `已绑定/已禁用的卡号`;
      }
      if (type == 3) {
        title = `当前卡号区间中存在已兑换的卡号，是否需要由系统自动将卡号区间拆分成多个卡号区间?`;
        msg = `已兑换的卡号`;
      }
      if (type == 4) {
        title = `当前卡号区间中存在未激活/已消费完的卡号，是否需要由系统自动将卡号区间拆分成多个卡号区间?`;
        msg = `未激活/已消费完的卡号`;
      }
      if (type == 5) {
        title = `当前卡号区间中存在未激活/已兑换的卡号，是否需要由系统自动将卡号区间拆分成多个卡号区间?`;
        msg = `已激活/已兑换的卡号`;
      }
      if (type == 6) {
        title = `当前卡号区间中存在不可开卡的卡号，是否需要由系统自动将卡号区间拆分成多个卡号区间?`;
        msg = `不可开卡的卡号`;
      }
      return new Promise((resolve, reject) => {
        this.$confirm(
          `<p style="font-weight: bold;">${title}</p>
          <div style="height: auto; max-height: 14em; overflow: auto; color: #f20;">
            (${msg}:${list.join(",")})
          </div>
        `,
          {
            title: "提示",
            dangerouslyUseHTMLString: true,
          }
        )
          .then(() => {
            resolve(1);
          })
          .catch(() => {
            resolve(2);
          });
      });
    },
    checkCardExportRules (res) {
      // status_rule:不符合未激活状态卡号
      // shop_rule:不符合所属商城卡号
      // use_rule:不符合未被其他销售单占用卡号
      // code_rule:不符合卡号规则卡号
      // rpt_rule:重复卡号
      // fee_rule:“已禁用”状态的卡号
      //part_exchange_rule:“部分兑换/全部兑换”状态的卡号
      const {
        shop_rule,
        use_rule,
        rpt_rule,
        app_type_rule,
        status_rule,
        fee_rule,
        part_exchange_rule,
        code_rule,
        other_rule,
        binding_or_disable_rule,
        exchange_rule,
        delivery_rule,//配送中
        shop_type,
      } = res;

      if (
        this.isEmpty(shop_rule) &&
        this.isEmpty(use_rule) &&
        this.isEmpty(rpt_rule) &&
        this.isEmpty(status_rule) &&
        this.isEmpty(fee_rule) &&
        this.isEmpty(part_exchange_rule) &&
        this.isEmpty(code_rule) &&
        this.isEmpty(app_type_rule) &&
        this.isEmpty(other_rule) &&
        this.isEmpty(binding_or_disable_rule) &&
        this.isEmpty(exchange_rule) &&
        this.isEmpty(delivery_rule)
      ) {
        return true;
      }
      let code_rule_err = this.getRule(code_rule, "规则不正确卡号");
      let app_type_rule_err = this.getRule(app_type_rule,"当前卡号类型与应用类型不匹配")
      let shop_rule_err = this.getRule(shop_rule, "非当前商城的卡号");
      let rpt_rule_err = this.getRule(rpt_rule, "重复的卡号");
      let status_rule_err = this.getRule(status_rule, "非“未激活”状态的卡号");
      let fee_rule_err = this.getRule(fee_rule, "“已禁用”状态的卡号");
      let part_exchange_rule_err = this.getRule(part_exchange_rule, "“部分兑换/全部兑换”状态的卡号");
      let use_rule_err = this.getRule(use_rule, "被占用的卡号");
      let other_rule_err = this.getRule(other_rule,"不属于当前关联销售单的卡号");
      let binding_or_disable_rule_err = this.getRule(binding_or_disable_rule,"已绑定/已禁用的卡号");
      let exchange_rule_err = this.getRule(exchange_rule, "“已兑换”的卡号");
      let delivery_rule_err = this.getRule(delivery_rule,"配送中/配送结束卡号");
      let title = "";
      if (code_rule_err) {
        title = "存在卡号规则不正确的卡号数据";
      }
      if (shop_rule_err) {
        title = "存在非当前商城的卡号数据";
      }
      if (app_type_rule_err) {
        title="当前卡号类型与应用类型不匹配"
      }
      if (rpt_rule_err) {
        title = "存在重复的卡号数据";
      }
      if (status_rule_err) {
        title = "存在非“未激活”状态的卡号数据";
      }
      if (fee_rule_err) {
        title = "存在“已禁用”状态的卡号数据";
      }
      if (part_exchange_rule_err) {
        title = "存在“部分兑换/全部兑换”状态的卡号数据";
      }
      if (use_rule_err) {
        title = "存在被其他销售单占用的卡号数据";
      }
      if (other_rule_err) {
        title = "存在不属于当前所关联销售单的卡号数据";
      }
      if (binding_or_disable_rule_err) {
        title = "存在“已绑定/已禁用”的卡号数据";
      }
      if (exchange_rule_err) {
        title = "存在“已兑换”的卡号数据";
      }
      if (delivery_rule_err) {
        title= "存在配送中/配送结束的卡号数据";
      }

      this.$alert(
        `<p style="font-weight: bold;">${title}，请重新填写或导入。</p>
        <div style="height: auto; max-height: 14em; overflow: auto; color: #f20;">
          ${
            code_rule_err ||
            shop_rule_err ||
            app_type_rule_err ||
            rpt_rule_err ||
            status_rule_err ||
            fee_rule_err ||
            part_exchange_rule_err ||
            use_rule_err ||
            other_rule_err ||
            binding_or_disable_rule_err ||
        exchange_rule_err ||
        delivery_rule_err
          }
        </div>
      `,
        {
          title: "提示",
          dangerouslyUseHTMLString: true,
        }
      );
      return false;
    },
    returnMatchSuggestion(type) {
      let newList;
      API_saleCard.getUserInputHistory(type).then((res) => {
        newList = res.map((item) => {
          return { value: item };
        });
        switch (type) {
          case "CLIENT":
            this.clientList = newList;
            break;
          case "AGENCY":
            this.agentList = newList;
            break;
          case "LINKMAN":
            this.linkNameList = newList;
            break;
          case "PHONE":
            this.phoneList = newList;
            break;
          case "ADDRESS":
            this.addressList = newList;
            break;
          case "SALESMAN":
            this.salesNameList = newList;
            break;
          case "WRIGHT":
            this.makeOrderList = newList;
            break;
        }
      });
    },
    clientScreen(queryString, cb) {
      let result = this.clientList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    agentScreen(queryString, cb) {
      let result = this.agentList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    linkNameScreen(queryString, cb) {
      let result = this.linkNameList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    phoneScreen(queryString, cb) {
      let result = this.phoneList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    addressScreen(queryString, cb) {
      let result = this.addressList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    salesManScreen(queryString, cb) {
      let result = this.salesNameList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    makeOrderScreen(queryString, cb) {
      let result = this.makeOrderList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      });
      cb(result);
    },
    // select方法
    phoneSelect(item) {
      this.formInfo.phone = item.value;
      this.handlePhoneChange(item.value);
    },
    getRowKeys(row) {
      return row.id;
    },
    getShopList (app_type_shop_type_flag, list = []) {
      let mall_type = 2;//现金
      if (app_type_shop_type_flag == 3) {//智能套餐卡
        mall_type = 4;
      } else if (app_type_shop_type_flag ==2 ) {//双选
        mall_type = 5;
      } else if (app_type_shop_type_flag==4||app_type_shop_type_flag==5) {//计次卡宅配卡
        mall_type = 6;
      }
      API_saleCard.getShopList({ mall_type }).then((res) => {
        this.shopList = res || [];
        // if (list.length > 0) {
        //   let _list = list.map((s) => s.shop_id);
        //   this.shopList = this.shopList.filter((s) =>
        //     _list.includes(s.shop_id)
        //   );
        // }
      });
    },
    roundNum(num, decimal = 10) {
      return Math.round(num * 10 ** decimal) / 10 ** decimal;
    },
    checkCardSave(cards, all = false) {
      for (const key in cards) {
        if (key != "" || all) {
          let list = cards[key];
          for (let i = 0; i < list.length; i++) {
            const { card_id, card_code_start, card_code_end } = list[i];
            if (!card_id || !card_code_start || !card_code_end) {
              return false;
            }
          }
        }
      }
      return true;
    },
    checkCardSaveX4(cards, all = false) {
      for (const key in cards) {
        if (key != "" || all) {
          let list = cards[key];
          for (let i = 0; i < list.length; i++) {
            const { shop_id, card_id, card_code_start, card_code_end } =
              list[i];
            if (!shop_id || !card_id || !card_code_start || !card_code_end) {
              return false;
            }
          }
        }
      }
      return true;
    },
  },
};

export default mixins;
